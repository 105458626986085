import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import Layout from "./../../templates/layout"
import { graphql } from 'gatsby'
import axios from 'axios'
import SEO from '../../components/core-module/organisms/seo/seo'
import * as style from './clubs.module.scss'
import Map from '../../components/core-module/molecules/maps/map'
import { redirect, urlGgeneratedByLanguage } from '../../utils/utils'
import { getTopAd, getUniqueSideAds, loadManyAds, randomPickOneAdCampaign } from '../../services/ads-service'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Emitter from '../../services/emitter'
import Advertisement, { AdsRequestAdapter } from '../../components/advertisments-module/atoms/advertisement/advertisement'
import {createUrlSlug} from '../../utils/utils'
import AdvertisementGroup from '../../components/advertisments-module/molecules/advertisement-group/advertisement-group'

const Clubs = () => {
    const translation = useTranslation()
    const { language } = useI18next()
    const [advertisements, setAdvertisements] = useState([])
    const [geoLocations, setGeoLocations] = useState([])

    useEffect(() => {
      loadManyAds(language, 'home=1').then(res => {
          let ads = getUniqueSideAds(res.data, 3)
          setAdvertisements(ads);
          let topAd = getTopAd(randomPickOneAdCampaign(res.data))
          Emitter.emit('TOP_AD_EVENT', AdsRequestAdapter(topAd))
      })
  }, [Emitter, language])

  useEffect(() => {
    axios.get(`${urlGgeneratedByLanguage(language)}/api/get-club-map-data`).then((result) => {
      let clubLocations = Object.values(result.data.data).map(club => ({
        ...club, 
        url: `/main/entity/${createUrlSlug(club.club_title)}?id=${club.id}`,
        title: club.club_title,
      }))
      setGeoLocations(clubLocations);
    })
  }, [])

    return (
        <Layout>
            <SEO title={translation.t('sitename') +' - ' + translation.t('advertisement.interests')} description={translation.t('advertisement.request.metaDescription')}/>
            <div className={style.container}>
              <h1>{translation.t('maps.title')}</h1>
              <div className={style.map}>
                <Map locations={geoLocations}/>
              </div>
              <div className={style.createYourFanPage}>
                <button className={style.button} onClick={() => redirect('/account/register-club/')}>{translation.t('maps.buttonRegisterClub')}</button>
              </div>
              <AdvertisementGroup advertisements={advertisements} className={style.groupAdvertisement}/>
            </div>
        </Layout>
    )
}

export default Clubs

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;