import React, { useState } from "react"
import {GoogleMap, useLoadScript, Marker, MarkerClusterer, OverlayView} from '@react-google-maps/api'
import * as style from './map.module.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {redirect} from '../../../../utils/utils'

const Map = ({locations}) => {
    const translation = useTranslation()
    const divStyle = {
      background: 'white',
      border: '1px solid #ccc',
      padding: 15
    };

    const {isLoaded} = useLoadScript({
      googleMapsApiKey: process.env.MAP_KEY
    })

    const [isOverlayShown, setIsOverlayShown] = useState(false)
    const [choosenClubName, setChoosenClubName] = useState('')
    const [choosenClubUrl, setChoosenClubUrl] = useState('')
    const [overlayCoordinates, setOverlayCoordinates] = useState({
      lat: 50.8476,
      lng: 4.3572
    })

    const redirectToClub = (url) => {
      redirect(url)
    }

    const showOverlay = (club) => {
      setOverlayCoordinates({
        lat: club.lat,
        lng: club.long
      })
      setIsOverlayShown(true)
      setChoosenClubName(club.title)
      setChoosenClubUrl(club.url)
    }
  
    if(!isLoaded) return <div>Loading...</div>
    return <GoogleMap 
              zoom={8}
              onDragStart={() => setIsOverlayShown(false)}
              onClick={() => setIsOverlayShown(false)}
              center={
                overlayCoordinates
              }
              mapContainerClassName={style.mapContainer}
            >

              {isOverlayShown && <OverlayView
                position={overlayCoordinates}
                onUnmount={() => setIsOverlayShown(false)}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div style={divStyle}>
                  <h6>{choosenClubName}</h6>

                  <button
                    onClick={() => redirectToClub(choosenClubUrl)}
                    type='button'
                  >
                    {translation.t('maps.checkClubPage')}
                  </button>
                </div>
              </OverlayView> }
              <MarkerClusterer>
                {
                (clusterer) => 
                  locations.map(
                    loc => (
                      <Marker 
                          key={loc.id} 
                          onClick={() => showOverlay(loc)}
                          clusterer={clusterer} 
                          position={{
                            lat: loc.lat,
                            lng: loc.long
                          }}
                      />
                    )
                  )
                }
              </MarkerClusterer>
            </GoogleMap>
  
}


export default Map