import React from 'react'
import Advertisement from '../../atoms/advertisement/advertisement'
import * as style from './advertisement-group.module.scss'

const AdvertisementGroup = ({className = '', advertisements = [], landscape=true}) => {

    return <div className={style.adGroup+' '+className}>
        {   
            advertisements.map((advertisement, pos) => {
                return <div className={style.ad}>
                    <Advertisement mediaUrl={advertisement.media_link}
                        addUUID={`${advertisement.id}-landing-${pos}`}
                        destinationLink={advertisement.url}
                        mediaType={advertisement.media_type}
                        campaignId={advertisement.campaignId} 
                    />
                </div>   
            })
        }
        </div>
}

export default AdvertisementGroup